<template>
  <div class="mainconunte">
    <rxNavBar title="推荐记录"></rxNavBar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          @load="initRecommendRecord"
      >
        <div class="staffData">
          <div class="dataDetailRightDataRow" >
            <div class="dataDetailRightData1 fontblod">姓名</div>
            <div class="dataDetailRightData fontblod">电话</div>
            <div class="dataDetailRightData2 fontblod">性别</div>
            <div class="dataDetailRightData3 fontblod">途径</div>

          </div>
          <div v-for="item in recommendRecordData" :key="item.id" class="dataDetailRightDataRow">
            <div class="dataDetailRightData1">{{ item.name }}</div>
            <div class="dataDetailRightData" >{{ item.phoneNum }}</div>
            <div class="dataDetailRightData2" >{{ item.gender_description }}</div>
            <div class="dataDetailRightData3" >{{ item.resumeWayStr }}</div>
          </div>
          <van-empty v-if="recommendRecordData.length == 0"
              :image="require('../../../assets/images/empty.png')"
              image-size="80"
              description="啊哦，这里好像什么都没有"
          />
<!--          <van-empty description="暂无数据" v-if="recommendRecordData.length == 0"/>-->
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {DatetimePicker, Divider, List, Popup, Cell, PullRefresh, Empty} from "vant";
import {checkAndroid, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {getInterPolateResumePage} from '../../../getData/getData'
export default {
  name: "recommendRecord",
  components: {
    rxNavBar,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [Divider.name]: Divider,
    [List.name]: List,
    [Cell.name]: Cell,
    [PullRefresh.name]:PullRefresh,
    [Empty.name]:Empty,
  },
  data(){
    return{
      searchStaffId:undefined,
      refreshing:false,
      loading:false,
      finished:false,
      recommendRecordData:[],
      recommendRecordPage:{
        currentPage:1,
        numberPage:10,
        pageCount:0,
      }
    }
  },
  mounted() {
  },
  activated() {
    this.recommendRecordPage.currentPage = 1
    this.recommendRecordPage.pageCount = 0
    this.recommendRecordData = []
    this.searchStaffId = this.$route.query.id
    this.onRefresh()
  },
  methods:{
//推荐记录
    initRecommendRecord(){
      if (this.refreshing) {
        this.refreshing = false;
      }
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.currentPage = this.recommendRecordPage.currentPage
      initData.numberPage = this.recommendRecordPage.numberPage
      initData.addStaff_id = this.searchStaffId
      getInterPolateResumePage(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.recommendRecordData = response.data.data.data!=undefined?that.recommendRecordData.concat(response.data.data.data):that.recommendRecordData.concat([])
          that.loading = false
          that.recommendRecordPage.currentPage++
          if(response.data.data.pageCount<=that.recommendRecordData.length){
            that.finished = true
          }
        })
      })
    },
    onRefresh(){
      this.finished = false
      this.loading = true
      this.initRecommendRecord()
    }
  }
}
</script>

<style scoped lang="less">
.mainconunte{
  padding: 0 10px 10px;
}
.fontblod {
  font-weight: bold;
}
.staffData{  //右侧数据
  width: 100%;
  // width: 80%;
  // display: inline-block;
  overflow:hidden;
  white-space:nowrap;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 7.5px;
  background-color: #FFFFFF;

  .dataDetailRightDataRow:nth-of-type(even){
    background-color: #fff;


    >.dataDetailRightData{
      background-color: #fff;


    }
    >.dataDetailRightData1 {
      background-color: #fff;
      border-right: 1px solid #f8f8f8;
      border-bottom: 1px solid #f8f8f8;

    }
    >.dataDetailRightData2 {
      background-color: #fff;
      border-bottom: 1px solid #f8f8f8;

    }
    >.dataDetailRightData3 {
      background-color: #fff;
      border-bottom: 1px solid #f8f8f8;

    }
  }
  .dataDetailRightDataRow{  //右侧数据横hang
    height: 33px;
    line-height: 33px;
    border-bottom: 1px solid #f8f8f8;
    .dataDetailRightData{     //右侧每个数据
      display:inline-block;
      width: 25%;
      padding-left: 15px;
      // background: red;
      overflow: hidden;
      font-size: 12px;
      height: 33px;
      border-right: 1px solid #f8f8f8;
      border-bottom: 1px solid #f8f8f8;
    }
    .dataDetailRightData1 {
      display:inline-block;
      width: 25%;
      padding-left: 15px;
      overflow: hidden;
      font-size: 12px;
      height: 33px;
      border-right: 1px solid #f8f8f8;
      border-bottom: 1px solid #f8f8f8;

    }
    .dataDetailRightData2 {
      display:inline-block;
      width: 10%;
      padding-left: 15px;
      overflow: hidden;
      font-size: 12px;
      height: 33px;
      border-bottom: 1px solid #f8f8f8;
    }
    .dataDetailRightData3 {
      display:inline-block;
      width: 20%;
      padding-left: 15px;
      overflow: hidden;
      font-size: 12px;
      height: 33px;
      border-bottom: 1px solid #f8f8f8;
    }

  }
}
</style>